import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import "./telemetryPage.css"
import { Telemetry } from "../../models/rocket/telemetry"
import rocketService from "../../services/rocketServiceImpl"
import { CommandSection } from "./commandSection/CommandSection"
import { NotFoundPage } from "../errorPages/NotFoundPage"
import { ScrollableSection } from "../../core-ui/scrollableSection/ScrollableSection"

export function TelemetryPage() {
    const { id } = useParams()

    const [telemetry, setTelemetry] = useState<Telemetry | null>(null)

    useEffect(() => {
        if(id) {
            const getTelemetryInterval = setInterval(async () => {
                try {
                    const tele = await rocketService.getNewestTelemetry(id)
                    setTelemetry(tele)
                } catch {
                    setTelemetry(null)
                }
            }, 500)

            return () => clearInterval(getTelemetryInterval)
        }
    }, [id])

    function renderStatus() {
        if(telemetry == null) {
            return (<h2>No status</h2>)
        } else {
            return Object.entries(telemetry.status).map(([key, value], _) => {
                return (
                    <div key={key} className="telemetry-grid-section--item">
                        <p>{key}</p>
                        <p>{value.toString()}</p>
                    </div>
                )
            })
        }
    }

    function renderChannels() {
        if(telemetry == null) {
            return (<h2>No channels</h2>)
        } else {
            return Object.entries(telemetry.cho).map(([key, value], _) => {
                return (
                    <div key={key} className="telemetry-grid-section--item">
                        <p>{key}</p>
                        <p>{value}</p>
                    </div>
                )
            })
        }
    }

    if(id) {        
        return (
            <div className="telemetry-page-container height-100">
                <ScrollableSection>
                    <h3 style={{margin: "4px"}}>Rocket: {id}</h3>
                            <CommandSection rocketService={rocketService} 
                                telemetry={telemetry}
                                rocketId={id}/>  
                </ScrollableSection>

                <ScrollableSection className="grid-row-span-2">
                    <h2>Status</h2>
                    {renderStatus()}
                </ScrollableSection>

                <ScrollableSection>
                    <h2>Channels</h2>
                    {renderChannels()}
                </ScrollableSection>
            </div>
        )
    } else {
        return <NotFoundPage/>
    }
}