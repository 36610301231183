import { Link, Outlet } from "react-router-dom";
import { routingPaths } from "../../routing/routingPaths";
import { AuthenticationImpl } from "../../services/authenticationImpl";
import "./layoutPage.css";
import { t } from "../../globaliztion"


export function LayoutPage() {
    const auth = AuthenticationImpl.getInstance()

    function logout() {
        auth.signOut()
    }

    return (
        <div>
            <div className="nav-bar">
                <Link to={routingPaths.home}>Tehiru</Link>
                <button onClick={logout}>{t.dict.logout}</button>
            </div>

            <main className="main-frame">
                <Outlet/>
            </main>
        </div>
    )
}