export enum Command {
    Arm = "arm",
    Disarm = "disarm",
    Ignite = "ignite",
    DeIgnite = "deignite",
    TestTvc = "testtvc",
    Calibrate = "calibrate",
    DeCalibrate = "decalibrate",
    OpenParachute = "openparachute",
    ResetTestTvc = "resettvc",
}

export enum CommandState {
    STATE_1,
    STATE_2,
    PENDING,
    DISABLE
}