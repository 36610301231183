import React, { useEffect } from "react";
import { Telemetry } from "../../../models/rocket/telemetry";
import "./commandSection.css"
import { RocketService } from "../../../services/rocketService";
import { Command } from "../../../models/rocket/command";
import { ArmDisarmButton } from "./buttons/ArmButton";
import { IgniteDeigniteButton } from "./buttons/IginiteButton";
import { CalibrateDecalibrateButton } from "./buttons/CalibrateButton";
import { TestTVCButton } from "./buttons/TestTvcButton";
import { ParachuteButton } from "./buttons/ParachuteButton";

export type CommandSectionPorps = {
    rocketId: string
    rocketService: RocketService
    telemetry: Telemetry | null
}

export function CommandSection(props: CommandSectionPorps) {

    useEffect(() => {
    }, [props.telemetry])

    function sendCommand(command: Command) {
        props.rocketService.sendCommand(props.rocketId ,command)
    }

    return (
        props.telemetry != null ? (
            <div className="command-section-grid">
                <ArmDisarmButton  
                    isArmed={props.telemetry.status.armed}
                    channel={props.telemetry.cho.ch4}
                    onClick={sendCommand}/>

                <IgniteDeigniteButton
                    channel={props.telemetry.cho.ch2}
                    isArmed={props.telemetry.status.armed}
                    onClick={sendCommand}/>  

                <CalibrateDecalibrateButton
                    channel={props.telemetry.cho.ch7}
                    isArmed={props.telemetry.status.armed}
                    onClick={sendCommand}/>

                <TestTVCButton
                    channel={props.telemetry.cho.ch8}
                    isArmed={props.telemetry.status.armed}
                    onClick={sendCommand}/>
                
                <ParachuteButton
                    channel={props.telemetry.cho.ch6}
                    flightStage={props.telemetry.status.flightStage}
                    iginited={props.telemetry.status.ignited}
                    onClick={sendCommand}/>       
            </div>
        ) :
        <></>
    )
}