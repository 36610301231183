import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { AuthenticationImpl } from "../services/authenticationImpl"
import { routingPaths } from './routingPaths';

export function GuardedOutlet() {
    const auth = AuthenticationImpl.getInstance()
    const [loggedIn, setLoggedIn] = useState(auth.loggedIn)

    useEffect(() => {
        const subscription = auth.subscribe((loggedIn: boolean) => setLoggedIn(loggedIn))
        return () => {
            auth.unSubscribe(subscription)
        }
    }, [auth])

    return loggedIn ? <Outlet /> : <Navigate to={routingPaths.login} />;
}
