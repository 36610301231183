import { TranslationDict } from "./translation";
import en from "./locals/en"

interface LagTranslator {

}

enum Languages {
  EN
}

class LagTranslatorImpl implements LagTranslator {
  private lang: Languages
  readonly dict: TranslationDict  

  constructor(){
    this.lang = Languages.EN
    this.dict = en
  }
}

const t = new LagTranslatorImpl()

export {
  Languages,
  t
};
