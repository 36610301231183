import React, { MouseEvent, useEffect, useState } from "react";
import { Command } from "../../../../models/rocket/command";
import { CommandButton, CommandButtonStyle } from "./CommandButton";
import "./commandButton.css";

enum CommandState {
    INITIAL,
    TVCTESTED,
    TVCNOTESTED,
    PENDING,
}


export type TestTvcCommandButtonPorps = {
    channel: number
    isArmed: boolean
    onClick: (command: Command) => void
}

export function TestTVCButton(props: TestTvcCommandButtonPorps) {
    const [currentState, setCurrentState] = useState(CommandState.INITIAL)
    const [prevState, setPrevState] = useState(CommandState.INITIAL)

    const [buttonText, setButtonText] = useState("TVC")
    const [buttonStyle, setButtonStyle] = useState<CommandButtonStyle>()

    useEffect(() => {
        if(currentState === CommandState.INITIAL) {
            const state = 1900 <= props.channel && props.channel <= 2100 ? 
            CommandState.TVCTESTED : 
            900 <= props.channel && props.channel <= 1000 ? 
            CommandState.TVCNOTESTED :
            CommandState.PENDING

            setPrevState(state)
            setCurrentState(state)
        } else {
            const state = 1900 <= props.channel && props.channel <= 2100 ? 
            CommandState.TVCTESTED : 
            900 <= props.channel && props.channel <= 1000 ? 
            CommandState.TVCNOTESTED :
            CommandState.PENDING

            if(state !== currentState) {
                    const tmpState = currentState
                    setCurrentState(state)
                    setPrevState(tmpState)
            }
        }
    }, [props.channel, currentState])

    useEffect(() => {
        if(currentState === CommandState.TVCNOTESTED) {
            setButtonText("Test TVC")
            setButtonStyle({background: "black"})
        } else if(currentState === CommandState.TVCTESTED) {
            setButtonText("Tested TVC")
            setButtonStyle({background: "green"})
        } else if(currentState === CommandState.PENDING) {
            setButtonStyle({background: "yellow"})
        }
    }, [currentState])

    // TODO check what to send in pending
    function onClick(event: MouseEvent<HTMLButtonElement>) {
        if(currentState === CommandState.PENDING) {
            props.onClick(Command.ResetTestTvc)
        } else if(currentState === CommandState.TVCNOTESTED){
            props.onClick(Command.TestTvc)
        }
    }

    return (
        <CommandButton
            text={buttonText}
            style={buttonStyle}
            disabled={props.isArmed}
            onClick={onClick}
        />
    )
}