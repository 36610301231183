import React, { MouseEvent, useEffect, useState } from "react";
import { Command } from "../../../../models/rocket/command";
import { CommandButton, CommandButtonStyle } from "./CommandButton";

enum CommandState {
    INITIAL,
    CALIBRATED,
    NOTCALIBRATED,
    PENDING,
}

export type  CalibrateCommandButtonPorps = {
    channel: number
    isArmed: boolean
    onClick: (command: Command) => void
}

export function CalibrateDecalibrateButton(props: CalibrateCommandButtonPorps) {
    const [currentState, setCurrentState] = useState(CommandState.INITIAL)
    const [prevState, setPrevState] = useState(CommandState.INITIAL)

    const [buttonText, setButtonText] = useState("")
    const [buttonStyle, setButtonStyle] = useState<CommandButtonStyle>()

    useEffect(() => {
        if(currentState === CommandState.INITIAL) {
            const state = 1900 <= props.channel && props.channel <= 2100 ? 
            CommandState.CALIBRATED : 
            900 <= props.channel && props.channel <= 1000 ? 
            CommandState.NOTCALIBRATED :
            CommandState.PENDING

            setPrevState(state)
            setCurrentState(state)
        } else {
            const state = props.channel && props.channel <= 2100 ? 
            CommandState.CALIBRATED : 
            900 <= props.channel && props.channel <= 1000 ? 
            CommandState.NOTCALIBRATED :
            CommandState.PENDING  

            if(state !== currentState) {
                    const tmpState = currentState
                    setCurrentState(state)
                    setPrevState(tmpState)
            }
        }
    }, [props.channel, currentState])

    useEffect(() => {
        if(currentState === CommandState.NOTCALIBRATED) {
            setButtonText("Calibrate")
            setButtonStyle({background: "black"})
        } else if(currentState === CommandState.CALIBRATED) {
            setButtonText("Calibrated")
            setButtonStyle({background: "green"})
        } else if(currentState === CommandState.PENDING) {
            setButtonStyle({background: "yellow"})
        }
    }, [currentState])

    function onClick(event: MouseEvent<HTMLButtonElement>) {
        if(currentState === CommandState.PENDING) {
            props.onClick(prevState === CommandState.CALIBRATED ? 
                Command.Calibrate :
                Command.DeCalibrate)   
        } else {
            props.onClick(currentState === CommandState.CALIBRATED ? 
                Command.DeCalibrate :
                Command.Calibrate)
        }
    }

    return (
        <CommandButton
            text={buttonText}
            style={buttonStyle}
            disabled={props.isArmed}
            onClick={onClick}
        />
    )
}