import React, { MouseEvent, useEffect, useState } from "react";
import { Command } from "../../../../models/rocket/command";
import { CommandButton, CommandButtonStyle } from "./CommandButton";
import "./commandButton.css";

enum CommandState {
    INITIAL,
    READY,
    OPEND,
    PENDING,
}

export type CommandButtonPorps = {
    channel: number
    flightStage: number
    iginited: boolean
    onClick: (command: Command) => void
}

export function ParachuteButton(props: CommandButtonPorps) {
    const [currentState, setCurrentState] = useState(CommandState.INITIAL)
    const [prevState, setPrevState] = useState(CommandState.INITIAL)

    const [buttonText, setButtonText] = useState("Parachute")
    const [buttonStyle, setButtonStyle] = useState<CommandButtonStyle>()

    useEffect(() => {
        if(currentState === CommandState.INITIAL) {
            const state = 1900 <= props.channel && props.channel <= 2100 &&  props.flightStage === -1 ? 
            CommandState.OPEND : 
            900 <= props.channel && props.channel <= 1000 ? 
            CommandState.READY :
            CommandState.PENDING

            setPrevState(state)
            setCurrentState(state)
        } else {
            const state = 1900 <= props.channel && props.channel <= 2100 &&  props.flightStage === -1 ? 
            CommandState.OPEND : 
            900 <= props.channel && props.channel <= 1000 ? 
            CommandState.READY :
            CommandState.PENDING

            if(state !== currentState) {
                    const tmpState = currentState
                    setCurrentState(state)
                    setPrevState(tmpState)
            }
        }
    }, [props.channel, props.flightStage, currentState])

    useEffect(() => {
        if(currentState === CommandState.READY) {
            setButtonText("Open parachute")
            setButtonStyle({background: "black"})
        } else if(currentState === CommandState.OPEND) {
            setButtonText("Parachute opend")
            setButtonStyle({background: "green"})
        } else if(currentState === CommandState.PENDING) {
            setButtonStyle({background: "red"})
        }
    }, [currentState])

    // TODO check what to send in pending
    function onClick(event: MouseEvent<HTMLButtonElement>) {
        if(currentState === CommandState.PENDING) {
            const tmpState = currentState
            setCurrentState(prevState)
            setPrevState(tmpState)
        } else if(currentState === CommandState.READY){
            const isExecuted = window.confirm("Are you sure you want to open the parachute?");
            if(isExecuted) {
                setPrevState(currentState)
                setCurrentState(CommandState.PENDING)
                props.onClick(Command.TestTvc)
            }
        }
    }

    return (
        <CommandButton
            text={buttonText}
            style={buttonStyle}
            disabled={props.iginited}
            onClick={onClick}
        />
    )
}