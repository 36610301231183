import React, { MouseEvent, useEffect, useState } from "react";
import { Command } from "../../../../models/rocket/command";
import { CommandButton, CommandButtonStyle } from "./CommandButton";

enum CommandState {
    INITIAL,
    IGNITED,
    NOTIGNITED,
}


export type IgniteCommandButtonPorps = {
    channel: number
    isArmed: boolean
    onClick: (command: Command) => void
}

export function IgniteDeigniteButton(props: IgniteCommandButtonPorps) {
    const [currentState, setCurrentState] = useState(CommandState.INITIAL)

    const [buttonText, setButtonText] = useState("")
    const [buttonStyle, setButtonStyle] = useState<CommandButtonStyle>()

    useEffect(() => {
        if(currentState === CommandState.INITIAL) {
            const state = 1900 <= props.channel && props.channel <= 2100 ? 
                CommandState.IGNITED : 
                CommandState.NOTIGNITED

            setCurrentState(state)
        } else {
            const state = 1900 <= props.channel && props.channel <= 2100 ? 
                CommandState.IGNITED : 
                CommandState.NOTIGNITED  

            if(state !== currentState) {
                    setCurrentState(state)
            }
        }
    }, [props.channel, currentState])

    useEffect(() => {
        if(currentState === CommandState.IGNITED) {
            setButtonText("Ignited")
            setButtonStyle({background: "green"})
        } else if(currentState === CommandState.NOTIGNITED) {
            setButtonText("Ignite")
            setButtonStyle({background: "black"})
        } 
    }, [currentState])

    function onClick(event: MouseEvent<HTMLButtonElement>) {
        props.onClick(currentState === CommandState.IGNITED ? 
            Command.DeIgnite :
            Command.Ignite)
    }

    return (
        <CommandButton
            text={buttonText}
            style={buttonStyle}
            disabled={props.isArmed}
            onClick={onClick}
        />
    )
}