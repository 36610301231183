import React, { ReactNode, useEffect, useState } from "react";
import './scrollableSection.css'

export type ScrollableSectionProps = {
    children: ReactNode
    className?: string
}

export function ScrollableSection(props: ScrollableSectionProps) {
    const [sectionClassesArr, setSectionClassesArr] = useState<string>("")

    useEffect(() => {
        const classNames = props.className ?
            "scrallable-section " + props.className :
            "scrallable-section"
        setSectionClassesArr(classNames)
    }, [props.className])

    return (
        <section className={sectionClassesArr}>
            <div className="scrallable-section--div-top">
                {props.children}
            </div>
        </section>
    )
}