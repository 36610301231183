import { http } from "../apiProvider/httpClient"
import { RocketApi } from "./rocketApi";
import { RokcetType, Rocket } from "../../models/rocket/rocket";
import { Telemetry } from "../../models/rocket/telemetry";
import { Command } from "../../models/rocket/command";

export class RocketApiImpl implements RocketApi {
    private client
    private readonly basePath: string

    constructor() {
        this.client = http
        this.basePath = "rocket"
    }
    
    async createRocket(name: string, type: RokcetType): Promise<boolean> {
        try {
            await this.client.post(`${this.basePath}`,
            {
                name: name,
                type: type,
            })

            return true
        } catch {
            return false
        }
    }

    async getAllRockets(): Promise<Rocket[]> {
        const response = await this.client.get<Rocket[]>(`${this.basePath}/all`)

        return response.data
    }

    async getNewestTelemetry(rocketId: string): Promise<Telemetry> {
        const response = await this.client.get<Telemetry>(`${this.basePath}/${rocketId}/telemetry`)

        return response.data
    }

    async sendCommand(rocketId: string, command: Command): Promise<void> {
        await this.client.post<Telemetry>(`${this.basePath}/${rocketId}/command/${command}`)
    }
}