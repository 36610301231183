import React, { ChangeEvent, FormEvent, useState } from "react"
import { RokcetType } from "../../../models/rocket/rocket"
import { RocketService } from "../../../services/rocketService"
import rocketService from "../../../services/rocketServiceImpl"

export type AddRocketFormProps = {
    rocketService: RocketService
    onSuccessfulRocketCreationCallback: () => void
}

export function AddRocketForm(props: AddRocketFormProps){
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")

    function handleNameChange(event: ChangeEvent<HTMLInputElement>){
        setName(event.target.value)
    }

    async function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        
        if(await rocketService.createRocket(name, RokcetType.GENERIC)) {
            setMessage("Rokcet was successfuly created")
            props.onSuccessfulRocketCreationCallback()
        } else {
            setMessage("Rokcet wasn't created")
        }
    }

    return (
        <div className="fl-cc border-solid">
            <label>{message}</label>
            <form className="fl-cc fl-g1" onSubmit={onSubmit}>
                <label>
                    Name:
                    <input type="text" name="name" required
                        value={name} onChange={handleNameChange}
                    />
                </label>
                <input type="submit" value="Create" />
            </form>
        </div>
    )
}