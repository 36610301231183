import React, { MouseEvent, useEffect, useState } from "react";
import { Command } from "../../../../models/rocket/command";
import { CommandButton, CommandButtonStyle } from "./CommandButton";

enum CommandState {
    INITIAL,
    ARMED,
    DISARMED,
    PENDING,
    DISABLED,
}

export type ArmCommandButtonPorps = {
    isArmed: boolean
    channel: number
    onClick: (command: Command) => void
}

export function ArmDisarmButton(props: ArmCommandButtonPorps) {
    const [currentState, setCurrentState] = useState(CommandState.INITIAL)
    const [prevState, setPrevState] = useState(CommandState.INITIAL)

    const [buttonText, setButtonText] = useState("")
    const [buttonStyle, setButtonStyle] = useState<CommandButtonStyle>()

    useEffect(() => {
        if(currentState === CommandState.INITIAL) {
            const state = props.isArmed ? CommandState.ARMED : 
            900 <= props.channel && props.channel <= 1100 ? CommandState.DISARMED :
            1900 <= props.channel && props.channel <= 2100 ? CommandState.PENDING :
            CommandState.DISABLED

            setPrevState(state)
            setCurrentState(state)
        } else {
            const state = props.isArmed ? CommandState.ARMED : 
            900 <= props.channel && props.channel <= 1100 ? CommandState.DISARMED :
            1900 <= props.channel && props.channel <= 2100 ? CommandState.PENDING :
            CommandState.DISABLED

            if(state !== currentState) {
                const tmpState = currentState
                setCurrentState(state)
                setPrevState(tmpState)
            }
        }
    }, [props.isArmed, props.channel, currentState])

    useEffect(() => {
        if(currentState === CommandState.ARMED) {
            setButtonText("Armed")
            setButtonStyle({background: "green"})
        } else if(currentState === CommandState.DISARMED) {
            setButtonText("Arm")
            setButtonStyle({background: "black"})
        } else if(currentState === CommandState.PENDING) {
            setButtonStyle({background: "yellow"})
        }
    }, [currentState])

    function onClick(event: MouseEvent<HTMLButtonElement>) {
        if(currentState === CommandState.PENDING) {
            props.onClick(prevState === CommandState.ARMED ? 
                Command.Arm :
                Command.Disarm)
        } else {
            props.onClick(currentState === CommandState.ARMED ? 
                Command.Disarm :
                Command.Arm)
        }
    }

    return (
        <CommandButton
            text={buttonText}
            style={buttonStyle}
            onClick={onClick}
            />
    )
}