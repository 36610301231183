import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import apiPovider from "../../api";
import { routingPaths } from "../../routing/routingPaths";
import { AuthenticationImpl } from "../../services/authenticationImpl";

export function GoogleSignOn() {
    const navigaotr = useNavigate()

    useEffect(() => {
        async function signinWithGoogle(response) {
            const tokenPair = await apiPovider.identity().signinWithGoogle(response.credential)
            const auth = AuthenticationImpl.getInstance()
    
            if(!auth.signIn(tokenPair.accessToken, tokenPair.refreshToken, tokenPair.expries)){
                alert('Error on signIn')
            } else {
                navigaotr(routingPaths.home)
            }
        }
        
        const script = document.createElement('script')
    
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
    
        document.body.appendChild(script);
    
        window.signinWithGoogle = signinWithGoogle

        return () => {
            document.body.removeChild(script)
            window.register = undefined
        }
      }, [navigaotr])
    
    return (
        <div>
            <div id="g_id_onload"
                data-client_id="1036830964217-3co2qq645ho42sv8rlbgqav3ufuejtd4.apps.googleusercontent.com"
                data-callback="signinWithGoogle"
                data-auto_prompt="false">
            </div>
            <div className="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="filled_blue"
                data-text="signup_with"
                data-shape="circle"
                data-logo_alignment="left">
            </div>     
        </div>
    )
}