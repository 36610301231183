import { IdentityApi } from "../identity/IdentityApi";
import { IdentityApiImpl } from "../identity/identityApiImpl";
import { RocketApi } from "../rocket/rocketApi";
import { RocketApiImpl } from "../rocket/rocketApiImpl";

export class ApiProvider {
    identity(): IdentityApi {
        return new IdentityApiImpl()
    }

    rocket(): RocketApi {
        return new RocketApiImpl()
    }
}
