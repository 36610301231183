import apiPovider from "../api";
import { RocketApi } from "../api/rocket/rocketApi";
import { Command } from "../models/rocket/command";
import { RokcetType, Rocket } from "../models/rocket/rocket"
import { Telemetry } from "../models/rocket/telemetry";
import { RocketService } from "./rocketService";

class RocketServiceImpl implements RocketService {
    private rocketApi: RocketApi

    constructor() {
        this.rocketApi = apiPovider.rocket()
    }

    async createRocket(name: string, type: RokcetType): Promise<boolean> {
        return await this.rocketApi.createRocket(name, type)
    }

    async getAllRockets(): Promise<Rocket[]> {
        return await this.rocketApi.getAllRockets()
    }

    async getNewestTelemetry(rocketId: string): Promise<Telemetry> {
        return await this.rocketApi.getNewestTelemetry(rocketId)
    }

    async sendCommand(rocketId: string, command: Command): Promise<void> {
        await this.rocketApi.sendCommand(rocketId, command)
    }
}

const rocketService = new RocketServiceImpl()
export default rocketService 