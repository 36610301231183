import React, { MouseEvent } from "react"
import "./CommandButton.tsx";

export type CommandButtonStyle = {
    background: string
}

export type CommandButtonProps = {
    text: string
    disabled?: boolean
    style?: CommandButtonStyle
    onClick: (event: MouseEvent<HTMLButtonElement>) => void
    
}

export function CommandButton(props: CommandButtonProps) {
    return (
        <button className="command-btn" onClick={props.onClick} 
        style={props.disabled ? {color: "black"} : props.style}
        disabled={props.disabled}>{props.text}</button>
    )
}