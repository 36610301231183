import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Rocket } from '../../models/rocket/rocket';
import { routingPaths } from '../../routing/routingPaths';
import rocketService from '../../services/rocketServiceImpl';
import { AddRocketForm } from './addRocket/AddRocketForm';
import "./homePage.css";

export function HomePage() {

    const [rokcets, setRockets] = useState<Rocket[]>([])

    async function getRockets() {
        try {
            const newRockets = await rocketService.getAllRockets()
            setRockets(newRockets)
        } catch {
            alert('Error fetching rockets')
        }
    }

    useEffect(() => {
        getRockets()
    }, [])

    const rocketList = rokcets.map((rocket, _) => {
        return <p key={rocket.id}>id: <Link to={`${routingPaths.rocket}/${rocket.id}/telemetry`}>{rocket.id}</Link>, name: {rocket.name}, type: {rocket.type}</p>
    })

    return (
        <div className="fl-cc fl-g1">
            <div className="rockets-list">
                { rocketList }
            </div>
            <AddRocketForm rocketService={rocketService} onSuccessfulRocketCreationCallback={getRockets}/>
        </div>
    )
}