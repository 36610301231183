import apiPovider from "../api"
import { Authentication, AuthSubscription } from "./authentication"

const REFRESH_TOKEN_KEY = "RFT"

type RefreshTokenStorge = {
    token: string
    exp: number,
}

export class AuthenticationImpl implements Authentication {
    private static instance: AuthenticationImpl
    private accessToken: string | null

    private subId : AuthSubscription
    private subscriptions: Map<AuthSubscription ,(login: boolean) => void>

    get token(): string | null {
        return this.accessToken
    }

    private constructor(){
        this.accessToken = null
        this.subscriptions = new Map()
        this.subId = 0
    }

    static getInstance(): AuthenticationImpl{
        if(AuthenticationImpl.instance == null) {
            AuthenticationImpl.instance = new AuthenticationImpl()
        }
        return AuthenticationImpl.instance 
    }

    async init() {
        await this.refreshTheToken()
    }

    signIn(accessToken: string, refreshToken: string, refreshTokenExp: number): boolean {
        this.accessToken = accessToken

        let refreshTokenJson = {
            token: refreshToken,
            exp: refreshTokenExp,
        }
        console.log(JSON.stringify(refreshTokenJson))
        window.localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(refreshTokenJson))
        
        this.subscriptions.forEach(callback => callback(true))
        return true
    }

    signOut(): void {
        window.localStorage.removeItem(REFRESH_TOKEN_KEY)
        this.accessToken = null

        this.subscriptions.forEach(callback => callback(false))
    }

    async refreshTheToken(): Promise<boolean> {
        if(this.refreshToken == null){
            return false
        }
        try {
            const resposne = await apiPovider.identity().refreshToken(this.refreshToken)
            this.accessToken = resposne.accessToken
            
            return true
        } catch {
            return false
        }
    }

    subscribe(callback: (login: boolean) => void): AuthSubscription {
        const authSub = this.subId
        this.subscriptions.set(authSub, callback)
        this.subId++
        return authSub
    }

    unSubscribe(subscription: AuthSubscription): void {
        this.subscriptions.delete(subscription)
    }

    private get refreshToken(): string | null {
        const refreshTokenStr = window.localStorage.getItem(REFRESH_TOKEN_KEY)
        if(refreshTokenStr == null) {
            return null
        }
        const refreshTokenJson: RefreshTokenStorge = JSON.parse(refreshTokenStr)
        if(Date.now() > refreshTokenJson.exp * 1000){
            this.signOut()
            return null
        }

        return refreshTokenJson.token
    }

    get loggedIn(): boolean {
        return this.accessToken != null
    }
}