import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { NotFoundPage } from './components/errorPages/NotFoundPage';
import { HomePage } from './components/home/HomePage';
import { LayoutPage } from './components/layout/LayoutPage';
import { LoginPage } from './components/login/LoginPage';
import { TelemetryPage } from './components/telemetry/TelemetryPage';
import { GuardedOutlet } from './routing/GuardedOutlet';
import { routingPaths } from './routing/routingPaths';
import { AuthenticationImpl } from './services/authenticationImpl';

function App() {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    async function init() {
      const auth = AuthenticationImpl.getInstance()
      await auth.init()

      setInitialized(true)
    }

    init()
  }, [])

  return (
    initialized ?
      <BrowserRouter>
          <Routes>
              <Route path={routingPaths.login} element={<LoginPage />} />
              <Route path={routingPaths.home} element={<GuardedOutlet />}>
                <Route element={<LayoutPage />}>
                  <Route path="" element={<HomePage />} />
                  <Route path={`${routingPaths.rocket}/:id/telemetry`} element={<TelemetryPage />} />
                </Route>
              </Route>
              <Route path="*" element={<NotFoundPage />} />
          </Routes>
      </BrowserRouter> :
      <h2>Initializing...</h2>
  );
}

export default App;
