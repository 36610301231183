import { IdentityApi } from "./IdentityApi";
import { http } from "../apiProvider/httpClient"
import { AccessTokenResponse, TokenPair } from "../../models/identity/auth";

export class IdentityApiImpl implements IdentityApi {
    private client
    private readonly basePath: string

    constructor() {
        this.client = http
        this.basePath = "identity"
    }

    async signinWithGoogle(googleToken: string): Promise<TokenPair> {
        const resposne = await this.client.post<TokenPair>(`${this.basePath}/signInGoogle`,
            {
                token: googleToken
            }
        )
        return resposne.data
    }

    async refreshToken(refreshToken: string): Promise<AccessTokenResponse> {
        const resposne = await this.client.get<AccessTokenResponse>(`${this.basePath}/refreshToken`,
            {
                params: {
                    token: refreshToken,
                }
            }
        )
        return resposne.data
    }
}