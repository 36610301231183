import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { NetworkError } from "../../shared/errors/network";
import { ServerError } from "../../shared/errors/server";
import { AxiosResponse } from "axios";
import { AuthenticationImpl } from "../../services/authenticationImpl";

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  "Content-Type": "application/json",
}

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp()
  }

  private auth: AuthenticationImpl = AuthenticationImpl.getInstance()

  initHttp() {
    const http = axios.create({
      baseURL: `${process.env.REACT_APP_GSUPPORT_BASE_URL}/api`,
      headers,
      withCredentials: true,
    })

    http.interceptors.request.use(Http.injectToken, (error) => Promise.reject(error));

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        return this.handleError(error)
      }
    );

    this.instance = http;
    return http;
  }

  private static injectToken = <D>(config: AxiosRequestConfig<D>): AxiosRequestConfig<D> => {
    const accessToken = AuthenticationImpl.getInstance().token

    if(accessToken != null){
      config.headers!['Authentication'] = `Bearer ${accessToken}`
    }

    return config
  }

  request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
    return this.http.request(config)
  }

  get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return this.http.get<T, R>(url, config)
  }

  post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config)
  }

  put<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config)
  }

  delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return this.http.delete<T, R>(url, config)
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private async handleError(error: Error | AxiosError) {
    if(axios.isAxiosError(error)){
        const originalRequest = error.config
        const { status } = error.response!;

        switch (status) {
        case StatusCode.InternalServerError: {
            // Handle InternalServerError
            break;
        }
        case StatusCode.Forbidden: {
            // Handle Forbidden
            break;
        }
        case StatusCode.Unauthorized: {
            return this.handleUnauthorized(error, originalRequest)
        }
        case StatusCode.TooManyRequests: {
            // Handle TooManyRequests
            break;
        } 
    }
        return Promise.reject(new ServerError())
    } else {
        console.log(`${error.message} ${error.stack}`)
        return Promise.reject(new NetworkError())
    }
  }

  private async handleUnauthorized(error: AxiosError, originalRequest: AxiosRequestConfig) {
    if(originalRequest.url!.includes("identity/refreshToken")){
      this.auth.signOut()
      window.location.href = '/login'
      return Promise.reject(error);
    } else {
      if(!await this.auth.refreshTheToken()){
        this.auth.signOut()
        window.location.href = '/login'
      }
      return this.http(originalRequest)
    }
  }
}

export const http = new Http()